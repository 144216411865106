import { template as template_e915dded808e4e4c92962692264e6fb1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e915dded808e4e4c92962692264e6fb1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
