import { template as template_667888cf93fa49d79828f939e789340f } from "@ember/template-compiler";
const FKLabel = template_667888cf93fa49d79828f939e789340f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
