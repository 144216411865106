import { template as template_489b4352606347d49030f73afc3ef328 } from "@ember/template-compiler";
const WelcomeHeader = template_489b4352606347d49030f73afc3ef328(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
