import { template as template_ab41d418e01846c39019435f34c8a9f5 } from "@ember/template-compiler";
const FKText = template_ab41d418e01846c39019435f34c8a9f5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
